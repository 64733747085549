export default [
    {
        img: "./images/jacket1.avif",
        type: "Dress",
        title: "Pique Fleece Neck Jacket",
        price: 28.80
    },
    {
        img: "./images/jacket2.avif",
        type: "Dress",
        title: "ColorBlock Slim Fit Jacket",
        price: 22.50
    },
    {
        img: "./images/jacket3.avif",
        type: "Dress",
        title: "Men Stripedd Slim Jacket",
        price: 60.99
    },
    {
        img: "./images/jacket4.avif",
        type: "Dress",
        title: "Chest Print Slim Jacket",
        price: 63.85
    },
    {
        img: "./images/jacket5.avif",
        type: "Dress",
        title: "Jacket with Kangaroo Pockets",
        price: 10.00
    },
    {
        img: "./images/jacket6.avif",
        type: "Dress",
        title: "Brand Print Fit Jacket",
        price: 53.00
    },
    {
        img: "./images/jacket7.avif",
        type: "Dress",
        title: "Typographic Print Jacket",
        price: 19.30
    },
    {
        img: "./images/jacket8.avif",
        type: "Dress",
        title: "Sleeve Less Jacket",
        price: 36.00
    },
]