import React from "react";
import { NavLink } from "react-router-dom";
import { useSharedState } from "./CartProvider";

interface Product {
  id: string;
  title: string;
  img: string;
  price: number;
}

const Cart = () => {
  const { productDetails, updateProductDetails } = useSharedState();
  const [quantities, setQuantities] = React.useState<{ [key: string]: number }>({});

  const handleQuantityChange = (productId: string, newQuantity: number) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
  };

  const calculatePreTotal = (product: Product) => {
    const quantity = quantities[product.id] || 1;
    return product.price * quantity;
  };

  const finalPrice = () => {
    let total = 0;

    productDetails.forEach((product) => {
        total += calculatePreTotal(product);
    });
    return total;
  };
  return (
    <main className="w-screen h-full bg-[whitesmoke] pb-5">
      <header id="top" className="w-screen flex flex-col justify-center">
        <div className="flex items-center justify-between w-[80vw] mt-10 mx-auto border-b pb-3">
          <div className=" font-serif flex gap-x-2 relative -right-16 md:static">
            <img src="./images/Group 97.svg" alt="" />
            <h2 className="font-serif text-2xl tracking-wider">CORAL</h2>
            <img src="./images/Group 97.svg" alt="" />
          </div>
          <nav>
            <ul className="flex text-[#1e2832] font-sans text-lg">
              <li className="relative -left-5 hover:text-red-500">
                <NavLink to="/Home">
                  <h2>
                    <i className="fa-solid fa-home"></i> &nbsp;
                    <span className="md:hidden">Home</span>
                  </h2>
                </NavLink>
              </li>
              <li className=" hover:text-red-500">
                <NavLink to="/Cart">
                  <h2>
                    <i className="fa-solid fa-bag-shopping"></i> &nbsp;{" "}
                    <span className="md:hidden">Shopping</span>
                  </h2>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section className="w-screen mt-10 bg-inherit pb-10">
        <div className="grid grid-cols-1 grid-rows-auto w-[85dvw] mx-auto gap-y-2">
          {productDetails.map((product, index) => (
            <div
              key={index}
              className="flex items-center justify-between border-b sm:gap-x-3"
            >
              <div className="w-[100px] h-[120px]">
                <img
                  src={product.img}
                  alt={product.title}
                  className="w-full h-full object-cover mr-4"
                />
              </div>
              <div>
                <h3 className="font-bold text-lg font-sans sm:text-sm">{product.title}</h3>
                <p className="text-gray-600 font-sans">${product.price}</p>
              </div>
              <input
                type="number"
                name="quantity"
                id=""
                className="text-center w-[50px]"
                value={quantities[product.id] || 1}
                onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value, 10))}
              />
              <p className="font-sans sm:text-sm">Pre-Total: ${calculatePreTotal(product)}</p>
            </div>
          ))}
        </div>
        <p className="font-sans text-xl relative float-right right-24 top-5">Sub Total: ${finalPrice()}</p>
      </section>
    </main>
  );
};

export default Cart;
