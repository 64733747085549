import React from "react";
import ScrollToTopBtn from "./ScrollToTopBtn";

const Footer = () => {
  return (
    <footer className="w-screen bg-white pb-20 relative">
      <div className="w-[85dvw] mx-auto mt-20 flex gap-16 pb-10 md:flex-col">
        <div className="w-[40%] flex flex-col gap-y-5 md:w-full">
          <div className=" font-serif flex gap-x-2">
            <img className="w-[40px]" src="./images/Group 97.svg" alt="" />
            <h2 className="font-serif text-2xl tracking-wider">CORAL</h2>
            <img className="w-[40px]" src="./images/Group 97.svg" alt="" />
          </div>
          <p className="font-sans text-sm font-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis earum
            neque placeat. Magnan impulse magnus opus is th ebest thing ever{" "}
          </p>
          <div className="w-[30%] flex justify-between">
            <i className="fa-brands fa-facebook hover:text-blue-500 duration-200 ease-in-out hover:scale-125 text-xl"></i>
            <i className="fa-brands fa-twitter hover:text-blue-400 duration-200 ease-in-out hover:scale-125 text-xl"></i>
            <i className="fa-brands fa-instagram hover:text-pink-500 duration-200 ease-in-out hover:scale-125 text-xl"></i>
            <i className="fa-brands fa-linkedin-in hover:text-blue-700 duration-200 ease-in-out hover:scale-125 text-xl"></i>
          </div>
        </div>
        <div className="w-[60%] flex justify-between md:w-full">
          <div className="flex flex-col gap-3">
            <h3 className="font-semibold text-lg xs:text-sm">CATALOG</h3>
            <p className="font-sans text-sm font-light">Necklaces</p>
            <p className="font-sans text-sm font-light">Hoodies</p>
            <p className="font-sans text-sm font-light">Jewelry Box</p>
            <p className="font-sans text-sm font-light">T-Shirt</p>
            <p className="font-sans text-sm font-light">Jackets</p>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="font-semibold text-lg xs:text-sm">ABOUT US</h3>
            <p className="font-sans text-sm font-light">Our Producers</p>
            <p className="font-sans text-sm font-light">Sitemap</p>
            <p className="font-sans text-sm font-light">FAQ</p>
            <p className="font-sans text-sm font-light">About Us</p>
            <p className="font-sans text-sm font-light">Terms & Conditions</p>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="font-semibold text-lg xs:text-sm">CUSTOMER SERVICES</h3>
            <p className="font-sans text-sm font-light">Contact Us</p>
            <p className="font-sans text-sm font-light">Track Your Order</p>
            <p className="font-sans text-sm font-light">
              Product Care & Repair
            </p>
            <p className="font-sans text-sm font-light">Book an Appointment</p>
            <p className="font-sans text-sm font-light">Shipping & Returns</p>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 flex items-center justify-center w-full bg-[#1e2832] h-[60px]">
        <div className="w-[70%] flex justify-between md:w-full ">
          <p className="font-sans text-lg text-white sm:text-sm">© 2022 Coral , Inc.</p>
          <div className="flex gap-x-1">
            <img
              className="w-[50px] h-[20px] object-scale-down hover:scale-110 duration-200 ease-in-out"
              src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg"
              alt=""
            />
            <img
              className="w-[50px] h-[20px] object-scale-down hover:scale-110 duration-200 ease-in-out"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Old_Visa_Logo.svg/2560px-Old_Visa_Logo.svg.png"
              alt=""
            />
            <img
              className="w-[50px] h-[20px] object-cover hover:scale-110 duration-200 ease-in-out"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/800px-American_Express_logo_%282018%29.svg.png"
              alt=""
            />
            <img
              className="w-[50px] h-[20px] object-scale-down hover:scale-110 duration-200 ease-in-out"
              src="https://i.pcmag.com/imagery/reviews/068BjcjwBw0snwHIq0KNo5m-15..v1602794215.png"
              alt=""
            />
            <img
              className="w-[50px] h-[20px] object-scale-down hover:scale-110 duration-200 ease-in-out"
              src="https://cdn.esewa.com.np/ui/images/esewa_og.png?111"
              alt=""
            />
          </div>
          <ScrollToTopBtn />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
