import React from "react";
import { Link } from "react-scroll";

const ScrollToTopBtn = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link
            to="top"
            spy={true}
            smooth={true}
            duration={500}
            className="font-sans text-white text-lg sm:text-sm"
            href="#"
          >
            Scroll To Top <i className="fa-solid fa-angle-up"></i>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default ScrollToTopBtn;
