import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Showcase from "./Showcase";
import Zara from "./Zara";
import Newsletter from "./Newsletter";
import Footer from "./Footer";
import Shop from "./Shop";

const Home = () => {
  return (
    <main className="w-screen h-screen scroll-smooth">
      <Header />
      <Hero />
      <Showcase />
      <Shop />
      <Zara />
      <Newsletter />
      <Footer />
    </main>
  );
};

export default Home;
