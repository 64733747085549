import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header id="top" className="w-screen flex flex-col justify-center">
      <div className="flex items-center justify-between w-[80vw] mt-10 mx-auto border-b pb-3">
        <button>
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
        <div className=" font-serif flex gap-x-2 relative -right-16 md:static">
          <img src="./images/Group 97.svg" alt="" />
          <h2 className="font-serif text-2xl tracking-wider">CORAL</h2>
          <img src="./images/Group 97.svg" alt="" />
        </div>
        <nav>
          <ul className="flex text-[#1e2832] font-sans text-lg">
            <li className="relative -left-5 hover:text-red-500">
              <NavLink to="/Home">
                <h2>
                  <i className="fa-solid fa-user"></i> &nbsp;<span className="md:hidden">Account</span>
                </h2>
              </NavLink>
            </li>
            <li className=" hover:text-red-500">
              <NavLink to="/Cart">
                <h2>
                  <i className="fa-solid fa-bag-shopping"></i> &nbsp; <span className="md:hidden">Shopping</span>
                </h2>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className="flex items-center justify-between w-[85vw] mx-auto ">
        <nav className="w-full pt-1">
          <ul className="flex items-center justify-between w-full sm:text-xs sm:flex-wrap sm:justify-evenly">
            <li className="p-1 hover:bg-teal-400 duration-200 ease-in-out">
              <a href="#">Jewlery & Accessories</a>
            </li>
            <li className="p-1 hover:bg-teal-400 duration-200 ease-in-out">
              <a href="#">Clothing & Shoes</a>
            </li>
            <li className="p-1 hover:bg-teal-400 duration-200 ease-in-out">
              <a href="#">Home & Living</a>
            </li>
            <li className="p-1 hover:bg-teal-400 duration-200 ease-in-out">
              <a href="#">Wedding & Party</a>
            </li>
            <li className="p-1 hover:bg-teal-400 duration-200 ease-in-out">
              <a href="#">Toys & Entertainment</a>
            </li>
            <li className="p-1 hover:bg-teal-400 duration-200 ease-in-out">
              <a href="#">Art & Collectibles</a>
            </li>
            <li className="p-1 hover:bg-teal-400 duration-200 ease-in-out">
              <a href="#">Craft Supplies & Tools</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
