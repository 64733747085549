export default [
    {
        img: "./images/tshirt2.avif",
        type: "Dress",
        title: "Logo Print Cotton T-Shirt",
        price: 24.80
    },
    {
        img: "./images/tshirt3.avif",
        type: "Dress",
        title: "Slim Fit Polo T-Shirt",
        price: 12.50
    },
    {
        img: "./images/tshirt4.avif",
        type: "Dress",
        title: "Regular Fit Neck T-Shirt",
        price: 60.99
    },
    {
        img: "./images/tshirt5.avif",
        type: "Dress",
        title: "Men Graphic Print T-Shirt",
        price: 63.85
    },
    {
        img: "./images/tshirt6.avif",
        type: "Dress",
        title: "Slim Fit Polo-Tshirt",
        price: 10.00
    },
    {
        img: "./images/tshirt7.avif",
        type: "Dress",
        title: "Washed T-Shirt with Brand",
        price: 53.00
    },
    {
        img: "./images/tshirt8.avif",
        type: "Dress",
        title: "Typographic Print Loose T-Shirt",
        price: 19.30
    },
    {
        img: "./images/tshirt9.avif",
        type: "Dress",
        title: "Basic T-Shirt",
        price: 236.00
    },
]