export default [
    {
        id: '1',
        img: "./images/image-product.png",
        type: "Dress",
        title: "Yellow Reserved Hoodie",
        price: 155.00
    },
    {
        id: '2',
        img: "./images/image-product-1.png",
        type: "Dress",
        title: "Nike Repel Miler",
        price: 120.50
    },
    {
        id: '3',
        img: "./images/image-product-2.png",
        type: "Glasses",
        title: "Nike Sportswear Luxe",
        price: 160.00
    },
    {
        id: '4',
        img: "./images/image-product-3.png",
        type: "Dress",
        title: "Adicolor Classic Joggers",
        price: 63.85
    },
    {
        id: '5',
        img: "./images/image-product-4.png",
        type: "Bag",
        title: "Nike Sportswear Bag",
        price: 130.00
    },
    {
        id: '6',
        img: "./images/image-product-5.png",
        type: "Scarf",
        title: "Geometric Pink Scarf",
        price: 53.00
    },
    {
        id: '7',
        img: "./images/image-product-6.png",
        type: "Shoes",
        title: "Nike Air Zoom Pegasus",
        price: 198.00
    },
    {
        id: '8',
        img: "./images/image-product-7.png",
        type: "Dress",
        title: "Basic Dress Green",
        price: 236.00
    },
]