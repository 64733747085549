import React from "react";

const Newsletter = () => {
  return (
    <section className="w-screen bg-[whitesmoke] pb-10">
      <div className="w-[85dvw] flex flex-col mx-auto items-center gap-y-16">
        <h2 className="font-sans text-5xl mt-10 sm:text-3xl text-center">
          Follow Products And Discounts On Instagram
        </h2>
        <div className="grid grid-cols-6 gap-5 lg:grid-rows-2 lg:grid-cols-3 duration-200 ease-in-out">
          <img
            className="w-[150px] h-[150px] object-cover rounded-xl hover:scale-110 duration-200 ease-in-out"
            src="./images/image-product-8.png"
            alt=""
          />
          <img
            className="w-[150px] h-[150px] object-cover rounded-xl hover:scale-110 duration-200 ease-in-out"
            src="./images/image-product-9.png"
            alt=""
          />
          <img
            className="w-[150px] h-[150px] object-cover rounded-xl hover:scale-110 duration-200 ease-in-out"
            src="./images/image-product-10.png"
            alt=""
          />
          <img
            className="w-[150px] h-[150px] object-cover rounded-xl hover:scale-110 duration-200 ease-in-out"
            src="./images/image-product-11.png"
            alt=""
          />
          <img
            className="w-[150px] h-[150px] object-cover rounded-xl hover:scale-110 duration-200 ease-in-out"
            src="./images/image-product-12.png"
            alt=""
          />
          <img
            className="w-[150px] h-[150px] object-cover rounded-xl hover:scale-110 duration-200 ease-in-out"
            src="./images/image-product-13.png"
            alt=""
          />
        </div>
        <h2 className="font-sans text-5xl sm:text-3xl xs:text-xl">Or Subscribe To The Newsletter</h2>
        <div className="flex gap-4 sm:flex-col sm:items-center sm:w-full">
          <input
            type="email"
            name="email"
            className="border-b border-black w-[400px] xs:w-[80%] h-[30px] bg-transparent pl-2 "
            placeholder="Email Address..."
            id=""
          />
          <button className=" bg-transparent font-sans text-xl border-b border-black hover:bg-slate-600 duration-200 ease-in-out rounded-sm hover:text-white px-1 sm:w-[100px]">SBMIT</button>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
