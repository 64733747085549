export default [
    {
        id: '1',
        img: "./images/hoodie1.avif",
        type: "Dress",
        title: "Pique Fleece Neck Hoodie",
        price: 28.80
    },
    {
        id: '2',
        img: "./images/hoodie2.avif",
        type: "Dress",
        title: "ColorBlock Slim Fit Hoodie",
        price: 22.50
    },
    {
        id: '3',
        img: "./images/hoodie3.avif",
        type: "Dress",
        title: "Men Stripedd Slim Hoodie",
        price: 60.99
    },
    {
        id: '4',
        img: "./images/hoodie4.avif",
        type: "Dress",
        title: "Chest Print Slim Hoodie",
        price: 63.85
    },
    {
        id: '5',
        img: "./images/hoodie5.avif",
        type: "Dress",
        title: "Hoodie with Kangaroo Pockets",
        price: 10.00
    },
    {
        id: '6',
        img: "./images/hoodie6.avif",
        type: "Dress",
        title: "Brand Print Fit Hoodie",
        price: 53.00
    },
    {
        id: '7',
        img: "./images/hoodie7.avif",
        type: "Dress",
        title: "Typographic Print Hoodie",
        price: 19.30
    },
    {
        id: '8',
        img: "./images/hoodie8.avif",
        type: "Dress",
        title: "Slim Fit Half Zip Hoodie",
        price: 36.00
    },
]