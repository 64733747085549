import React from "react";
import allProducts from "./AllProductsArr";
import allTshirts from "./T-ShirtArr";
import allHoodies from "./HoodieArr";
import allJackets from "./JacketsArr";
import { useSharedState } from "./CartProvider";
import "./App.css";

interface ProductType {
  img: string;
  type: string;
  title: string;
  price: number;
}

const Shop = () => {
  const [activated, setActivated] = React.useState("All Products");
  const [currentArray, setCurrentArray] =
    React.useState<Array<ProductType>>(allProducts);
  const [showSortBy, setShowSortBy] = React.useState(false);
  const [selectedSortOption, setSelectedSortOption] =
    React.useState("Popularity");
  const { productDetails, updateProductDetails } = useSharedState();
  const [popupVisible, setPopupVisible] = React.useState(false);

  const handleNavClick = (navItem: any) => {
    setActivated(navItem);

    if (navItem === "T-Shirts") {
      setCurrentArray(allTshirts);
    } else if (navItem === "Hoodies") {
      setCurrentArray(allHoodies);
    } else if (navItem === "Jackets") {
      setCurrentArray(allJackets);
    } else {
      setCurrentArray(allProducts);
    }
  };

  const handleClick = (productDetails: {
    id: string;
    title: string;
    img: string;
    price: number;
  }) => {
    updateProductDetails((prevProductDetails) => [
      ...prevProductDetails,
      productDetails,
    ]);
    setPopupVisible(true);
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPopupVisible(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [popupVisible]);

  const handleFilterClick = () => {
    setShowSortBy(!showSortBy);
  };

  const handleSortOptionClick = (sortOption: any) => {
    setSelectedSortOption(sortOption);
    setShowSortBy(false);

    if (sortOption === "Price: High to Low") {
      currentArray.sort((a, b) => b.price - a.price);
    } else if (sortOption === "Price: Low to High") {
      currentArray.sort((a, b) => a.price - b.price);
    } else {
      // Default to Popularity Sorting
    }
  };

  const mappedArray = currentArray.map((product: any) => (
    <div
      key={product.id}
      className="flex flex-col gap-y-2 group duration-200 ease-in-out"
    >
      <div className="w-full relative flex justify-center group-hover:scale-110 duration-200 ease-in-out">
        <img
          className="w-full h-[200px] object-scale-fill"
          src={product.img}
          alt=""
        />
        <button
          onClick={() =>
            handleClick({
              id: product.id,
              title: product.title,
              img: product.img,
              price: product.price,
            })
          }
          className="px-4 py-1 font-sans bg-slate-800 text-white absolute bottom-10 rounded-md hidden hover:text-red-500 group-hover:block duration-200 ease-in-out"
        >
          Buy Now
        </button>
      </div>
      <p className="font-sans text-base">{product.title}</p>
      <div className="flex justify-between">
        <p className="font-sans font-light text-base">{product.type}</p>
        <p className="font-sans text-base">${product.price}</p>
      </div>
    </div>
  ));
  return (
    <section className="w-screen mt-20 bg-[whitesmoke] pb-8">
      <div className="w-[80dvw] mx-auto flex flex-col items-center gap-y-10">
        <h1 className="text-4xl font-sans">Start Shopping</h1>
        <div className="w-full flex justify-between xs:flex-col xs:gap-y-3">
          <nav>
            <ul className="flex gap-x-5 font-sans text-gray-600 text-lg sm:text-sm ">
              <li>
                <a
                  href="#/"
                  className={activated === "All Products" ? "text-red-500" : ""}
                  onClick={() => handleNavClick("All Products")}
                >
                  All Products
                </a>
              </li>
              <li>
                <a
                  href="#/"
                  className={activated === "T-Shirts" ? "text-red-500" : ""}
                  onClick={() => handleNavClick("T-Shirts")}
                >
                  T-Shirts
                </a>
              </li>
              <li>
                <a
                  href="#/"
                  className={activated === "Hoodies" ? "text-red-500" : ""}
                  onClick={() => handleNavClick("Hoodies")}
                >
                  Hoodies
                </a>
              </li>
              <li>
                <a
                  href="#/"
                  className={activated === "Jackets" ? "text-red-500" : ""}
                  onClick={() => handleNavClick("Jackets")}
                >
                  Jackets
                </a>
              </li>
            </ul>
          </nav>
          <button
            onClick={handleFilterClick}
            className="px-4 py-1 bg-[#1e2832] text-white font-sans text-center xs:w-[30%] hover:text-red-500 duration-200 ease-in-out"
          >
            <i className="fa-solid fa-filter"></i> Filter
          </button>
        </div>
        {showSortBy && (
          <div className="bg-slate-200 px-3 absolute group flex flex-col py-1 mt-[83px] rounded-lg right-[250px] hover:bg-[#1e2832] ease-in-out z-50">
            <p className="font-sans text-sm text-black group-hover:text-white">
              Sort by
            </p>
            <div className="hidden w-full group-hover:flex group-hover:flex-col bg-[#1e2832] mt-1 gap-y-3 z-50">
              <div className="font-sans text-white w-full hover:bg-teal-400 rounded-lg ">
                Popularity
              </div>
              <div
                onClick={() => handleSortOptionClick("Price: High to Low")}
                className="font-sans text-white w-full hover:bg-teal-400 rounded-lg text-center"
              >
                Price: High to Low
              </div>
              <div
                onClick={() => handleSortOptionClick("Price: Low to High")}
                className="font-sans text-white w-full text-center hover:bg-teal-400 rounded-lg"
              >
                Price: Low to High
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-4 mt-4 grid-rows-auto gap-5 w-full h-[550px] md:grid-cols-3 md:overflow-scroll sm:grid-cols-2">
          {mappedArray}
        </div>
        {popupVisible && (
          <div
            className={`fixed bottom-5 left-0 right-0 bg-gray-800 text-white p-4 text-center w-[30%] mx-auto rounded-lg transition-transform ${
              popupVisible
                ? 'animate-slideIn'
                : "animate-slideOut"
            }`}
          >
            <p className="font-sans text-xl">Added to Cart Successfully!</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Shop;
