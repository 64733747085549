import React from 'react'

const Showcase = () => {
  return (
    <section className='w-screen bg-white mt-4'>
        <div className='w-[80vw] flex flex-col mx-auto relative top-16 bg-inherit'>
            <div className='w-full flex gap-2'>
                <div className='w-[200px]'><img className='w-full object-contain' src="./images/brand-1.png" alt="" /></div>
                <div className='w-[200px]'><img className='w-full object-contain' src="./images/brand-2.png" alt="" /></div>
                <div className='w-[200px]'><img className='w-full object-contain' src="./images/brand-3.png" alt="" /></div>
                <div className='w-[200px]'><img className='w-full object-contain' src="./images/brand-4.png" alt="" /></div>
                <div className='w-[200px]'><img className='w-full object-contain' src="./images/brand-5.png" alt="" /></div>
            </div>
            <div className='grid grid-cols-12 grid-rows-6 gap-5 h-[450px] mt-10 w-full'>
                <div className=' row-span-6 col-span-6 md:col-span-12'><img className='w-full h-full object-fill md:object-cover' src="./images/image-category-1.png" alt="" /></div>
                <div className='col-span-3 row-span-3'><img className='w-full h-full object-fill' src="./images/item-category-hover 1.png" alt="" /></div>
                <div className='col-span-3 row-span-3'><img className='w-full h-full object-fill' src="./images/item-category-2.png" alt="" /></div>
                <div className='row-span-3 col-span-3'><img className='w-full h-full object-fill' src="./images/item-category-1.png" alt="" /></div>
                <div className='col-span-3 row-span-3'><img className='w-full h-full object-fill' src="./images/item-category.png" alt="" /></div>
            </div>
            <h2 className='font-sans text-2xl -rotate-90 relative -left-[530px] tracking-wider -top-[540px]'>EXPLORE NEW AND POPULAR STYLES</h2>
        </div>
    </section>
  )
}

export default Showcase