import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface ProductDetails {
  id: string;
  title: string;
  img: string;
  price: number;
}

interface SharedStateContextProps {
  productDetails: ProductDetails[];
  updateProductDetails: Dispatch<SetStateAction<ProductDetails[]>>;
}

const SharedStateContext = createContext<SharedStateContextProps | undefined>(
  undefined
);

export const useSharedState = () => {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error("useSharedState must be used within a SharedStateProvider");
  }
  return context;
};

interface SharedStateProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<SharedStateProviderProps> = ({
  children,
}) => {
  const [productDetails, updateProductDetails] = useState<ProductDetails[]>([]);

  const contextValue: SharedStateContextProps = {
    productDetails,
    updateProductDetails,
  };
  return (
    <SharedStateContext.Provider value={contextValue}>
      {children}
    </SharedStateContext.Provider>
  );
};
