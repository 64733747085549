import React from "react";

const Hero = () => {
  return (
    <section className="w-full bg-inherit">
      <div className="flex w-[80vw] mx-auto mt-20">
        <div className=" w-[70%] flex flex-col gap-y-8">
          <h1 className=" font-sans font-light text-7xl sm:text-4xl">Collections</h1>
          <h3 className="font-sans font-normal text-2xl leading-loose sm:text-lg">
            You Can Explore Any Shop From Many Different Collection From Various
            Brands Here
          </h3>
          <button className="group bg-[#1e2832] w-[170px] h-[50px] text-center flex items-center justify-center gap-2 text-white hover:bg-white hover:text-[#1e2832] duration-200 ease-in-out"><i className="fa-solid fa-bag-shopping text-white group-hover:text-[#1e2832] group-hover:duration-200 group-hover:ease-in-out"></i><h2 className="font-sans text-xl font-medium">Shop Now</h2></button>
        </div>
        <div className="w-[30%]">
            <div className="w-[20dvw] h-[350px] border-purple-800 border rounded-tl-[120px] rounded-br-[120px] overflow-clip">
                <img className="w-full h-full object-cover" src="./images/engin-akyurt-jaZoffxg1yc-unsplash 1.png" alt="" />
            </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
