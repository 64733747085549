import React from "react";
import Home from "./Home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  RouteProps,
  Navigate,
} from "react-router-dom";
import Cart from "./Cart";

const App = () => {
  return (
    <Router>
      <div className="w-screen cursor-pointer bg-[whitesmoke]">
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Cart" element={<Cart />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
