import React from "react";

const Zara = () => {
  return (
    <section className="w-screen h-[500px] mt-10 ">
      <div className="w-full h-full relative">
        <img
          className="w-full h-full object-cover -z-1"
          src="./images/andrey-zvyagintsev-EQj1ZMpq_VM-unsplash 1.png"
          alt=""
        />
        <img className="w-[400px] h-[350px] object-contain z-10 absolute right-28 top-36 -mt-40 sm:w-[250px] sm:h-[250px]" src="./images/1024px-Zara_Logo 1.png" alt="" />
        <div className="w-1/2 flex flex-col z-20 relative float-right -mt-[400px] gap-y-10">
            <div className="w-[20%] h-[50px] sm:w-[80%]"><img className="w-full h-full object-contain" src="./images/1024px-Zara_Logo 2.png" alt="" /></div>
          <h2 className="font-sans text-xl text-white sm:text-base">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti,
            sunt fugiat, eos voluptates hic cum officia soluta vero qui porro id
            dolores aliquam, officiis corporis excepturi. A expedita architecto
            qui!
          </h2>
          <button className="bg-white w-[170px] h-[50px] text-center flex items-center justify-center gap-2 font-sans text-xl">
            See Collection
          </button>
        </div>
      </div>
    </section>
  );
};

export default Zara;
